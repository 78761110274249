$(document).ready(initPage);

function initPage() {
  $.fn.btnLoadmore = function (options) {
    var defaults = {
        showItem: 4,
        whenClickBtn: 4,
        textBtn: "Load more ...",
        classBtn: "",
        setCookies: false,
        delayToScroll: 1000
      },
      options = $.extend(defaults, options);

    this.each(function () {
      var $this = $(this),
        $childrenClass = $($this.children());

      // Get Element Of contents to hide
      $childrenClass.hide();

      //Show Element from Options
      $childrenClass.slice(0, defaults.showItem).show();

      $(document).on("click", ".btn-loadmore", function (e) {
        e.preventDefault();
        $childrenClass
          .filter(":hidden")
          .slice(0, defaults.whenClickBtn)
          .slideDown();
        if ($childrenClass.filter(":hidden").length == 0) {
          $(".btn-loadmore").fadeOut("slow");
        }
        scrollDown();
      });

      function scrollDown() {
        $("html, body").animate(
          {
            scrollTop: $childrenClass.filter(":visible").last().offset().top
          },
          defaults.delayToScroll
        );
      }
    });
  };

  $(".menu-toggler").click(function () {
    $("body").toggleClass("show-menu");
  });

  let stickyTop = 0;
  setSticky();

  function setSticky() {
    var windowTop = $(window).scrollTop();
    if (stickyTop < windowTop) {
      $("body").addClass("sticky-header");
    } else {
      $("body").removeClass("sticky-header");
    }
  }

  $(window).scroll(function () {
    setSticky();
  });

  let swiperthumbs = new Swiper(".swiper-thumbs", {});
  let swiper = new Swiper(".swiper-main", {
    spaceBetween: 10,
    effect: "fade",
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    thumbs: {
      swiper: swiperthumbs
    }
  });

  $(".up").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "fast");
  });

  $(".newslist-more").btnLoadmore({});

  $(".header-menu .parent > span").click(function () {
    $(this).parent().toggleClass("expanded");
  });
}
